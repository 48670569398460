<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Transfer Account Ownership </h3>
      </div>
      <div class="stats-list side-menu">
          <div class="single-stat">
            <el-row type="flex">
              <el-col>
                <el-form :model="ruleForm" label-position="top"  :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <span>
                        Your account is currently linked to the email address <b>{{ getAuthenticatedUser.email }}</b>. We have sent a one-time password (OTP) to both the current and requested email addresses. This OTP will expire in 10 minutes.
                    </span>
                    <el-form-item label="Email OTP"  prop="old_email_otp">
                        <el-input v-model.number="ruleForm.old_email_otp"></el-input>
                      </el-form-item>
                    <el-form-item label="New Account Email OTP"  prop="new_email_otp">
                      <el-input v-model.number="ruleForm.new_email_otp"></el-input>
                    </el-form-item>

                    <el-form-item>
                      <el-button type="primary" @click="submitForm('ruleForm')" size="mini">Validate</el-button>
                      <!-- <el-button @click="resetForm('ruleForm')" size="mini">Reset</el-button> -->
                    </el-form-item>
                    <span @click="resendOtp" style="cursor:pointer">Resend OTP</span>
                  </el-form>
              </el-col>
            </el-row>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import SysAxios from "@/config/axios";
import appConfig from '@/config/app';
import authHelper from '@/mixins/authHelper';
export default {
  name: "Dashboard-Stats",
  async mounted() {
    this.loading = true;
    // await this.fetchUserdashboardStatics();
    // await this.setData();
    this.loading = false;
  },
  mixins: [ authHelper],
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("settings", [
      "getUserDashboardStastics",
      "getUpdateUserDashboardStaticsStatus",
    ]),
  },
  methods: {
    async resendOtp(){
        this.loading=true;
        try{
            let response=await SysAxios.get(`/transferOwnership/${this.$route.params.requestId}`);
            console.log(response);
            this.alert("success",'OTP Sent','Resend OTP to both the email`s','Done');
            this.loading=false;
        }
        catch(err){
            console.log(err)
            this.alert("error",'','Failed to send OTP`s','');
            this.loading=false;
        }
    },
    async submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
                try{
                    this.loading=true;
                    let payload={...this.ruleForm};
                    let response=await SysAxios.post(`/transferOwnership/${this.$route.params.requestId}`,payload);
                    console.log(response);
                    Swal.fire(
                        {
                            title:"Account Transferred !",
                            text: "Account has been transferred successfully. You need to logout and login again!",
                            allowOutsideClick:false,
                            allowEscapeKey:false,
                            icon: "success",
                            showConfirmButton: true,
                            showCancelButton:false,
                            showDenyButton:false,
                            confirmButtonText:'Logout'
                        }
                    ).then((result) => {
                        console.log(result)
                        let timerInterval;
                        Swal.fire({
                            title: "",
                            html: "Please wait...... Signing off ..... ",
                            timer: 1500,
                            timerProgressBar: true,    
                            allowOutsideClick:false,
                            allowEscapeKey:false,
                            didOpen: () => {
                            Swal.showLoading();
                            },
                            willClose: () => {
                            clearInterval(timerInterval);
                            this.logout();
                            }
                        })
                    })
                }
                catch(err)
                {
                    console.log(err)
                    this.loading=false;
                    this.$message.error(err?.response?.data?.message || err?.message || "Failed to verify your request")
                }


          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    async alert(info="info",title='',message,confirmButtonText='Ok')
    {
        Swal.fire(
                        {
                            title:title,
                            text: message,
                            allowOutsideClick:false,
                            allowEscapeKey:false,
                            icon: info,
                            showConfirmButton: true,
                            showCancelButton:false,
                            showDenyButton:false,
                            confirmButtonText:confirmButtonText
                        }
                    )
    }
  },
  data() {
    return {

        loading: false,
        serverBaseURL: appConfig.AUTHFAST_BASE_URL,
        ruleForm: {
            old_email_otp: '',
            new_email_otp: ''
        },
        rules: {
          old_email_otp: [
            { required: true, message: 'Please enter current email OTP', trigger: 'blur' },
            { type: 'number', message: 'Please input correct OTP', trigger: ['blur', 'change'] }
          ],
          new_email_otp: [
            { required: true, message: 'Please enter new Email OTP', trigger: 'blur' },
            { type: 'number', message: 'Please input correct OTP', trigger: ['blur', 'change'] }
          ]
        }
    };
  },

};
</script>

<style lang="scss" scoped>
.side-menu{
  height: 580px;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width:thin;
  border-top:1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  
}
.side-menu::-webkit-scrollbar {
   width: 0.7em;
}
.side-menu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9); 
}
.side-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5; 
    background-color: #F5F5F5;

}
.stats-list {
  .single-stat {
    padding: 1em 1.5em;
  }
  .stat-item:last-child {
    .stat-divider {
      display: none;
    }
  }
}
</style>